
  import { Component, Watch } from 'vue-property-decorator'
  import { BaseCustomForm } from '@/components/person/BasicCustomForm'
  import GAlert from '@/components/core/alert/GAlert.vue'
  import LaborDataContent from '@/components/person/laborData/LaborDataContent.vue'
  import AddressAlignment from '@/components/person/AddressAlignment.vue'
  import GDatePicker from '@/components/core/input/GDatePicker.vue'
  import DesktopForm from '@/components/forms/view/DesktopForm.vue'
  import FormTitle from '@/components/forms/FormTitle.vue'
  import MobileForm from '@/components/forms/view/MobileForm.vue'
  import ReferenceAlignment from '@/components/person/ReferenceAlignment.vue'
  import BankDataAlignment from '@/components/person/BankDataAlignment.vue'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { isEmpty } from '@/utils/general'
  import { BankDataFulled, BankDataFulledBiPersonal } from '@/store/persons/bank/state'
  import { BankData, Details } from '@/utils/generalInterface'

@Component({
  components: {
    BankDataAlignment,
    ReferenceAlignment,
    MobileForm,
    FormTitle,
    DesktopForm,
    GDatePicker,
    AddressAlignment,
    LaborDataContent,
    GAlert,
  },
  methods: {
    ...mapMutations('persons/bank', [
      'setAccountAdded',
      'setAccountFound',
      'setBiPersonalInfo',
      'setAddAccountState',
      'setRepeatedAccount',
    ]),
    ...mapActions('persons/bank', [
      'findBankAccount',
      'updateBankDetails',
      'createBankDetails',
      'loadAccountTypes',
      'loadBanks',
    ]),
  },
  computed: {
    ...mapGetters('persons/bank', [
      'addAccount',
      'isAccountAdded',
      'accountFound',
      'banks',
      'accountTypes',
      'biPersonalInfo',
    ]),
  },
})
  export default class BankDataForm extends BaseCustomForm {
  setBiPersonalInfo!: (biPersonalInfo: BankDataFulled | null) => void;
  createBankDetails!: (bank: BankData) => Promise<void>;
  updateBankDetails!: ({ bank, personId, biPersonal }: {
    bank: BankDataFulled;
    personId: string;
    biPersonal: boolean;
  }) => void;

  accountFound!: BankDataFulled | null;
  accountTypes!: Details[];
  banks!: Details[];
  loadAccountTypes!: () => void;
  loadBanks!: () => void;
  setAccountAdded!: (value: boolean) => void;
  addAccount!: boolean;
  biPersonalInfo!: BankDataFulled | null;
  bankAccount = {
    bankId: -1,
    accountType: '',
    accountNumber: '',
  };

  bipersonal = false
  alertTitle = '¿Es una cuenta bipersonal?'

  async mounted () {
    this.loadBanks()
    this.loadAccountTypes()
    this.initBankData()
  }

  get accountDisabled (): boolean {
    return isEmpty(this.bankAccount.accountNumber) || !this.addAccount
  }

  findBankAccount!: ({ bankId, personId }: {
    bankId: string;
    personId: string;
  }) => void;

  findBankAccountData (val: string): void {
    const { getFoundPerson } = this
    if (!getFoundPerson?.id || !val?.length) return

    this.findBankAccount({
      bankId: val,
      personId: getFoundPerson.id.toString(),
    })
  }

  confirmAddBiPersonalData (): void {
    const { getFoundPerson, biPersonalInfo } = this
    if (getFoundPerson?.id && biPersonalInfo) {
      this.updateBankDetails({
        bank: biPersonalInfo,
        personId: getFoundPerson.id.toString(),
        biPersonal: true,
      })
    }
    this.setBiPersonalInfo(null)
    this.$router.back()
  }

  cleanBiPersonal (): void {
    this.openAlert = false
    this.initBankData()
  }

  @Watch('biPersonalInfo', { immediate: true, deep: true }) onBiPersonalFound (
    val: BankDataFulledBiPersonal
  ): void {
    if (val && val.id && val.personAccounts) {
      this.alertSubtitle = `Cotitular: ${val.personAccounts.person?.firstName} ${val.personAccounts.person?.surname} / Rut: ${val.personAccounts.person?.rut}`
      this.openAlert = true
    }
  }

  @Watch('accountFound', { immediate: true, deep: true }) onAccountFound (
    val: BankDataFulled
  ): void {
    if (val && val.idBank) {
      this.bankAccount.bankId = val.idBank
      this.bankAccount.accountType = val.accountType.name
      this.bipersonal = val?.bipersonal
    }
  }

  initBankData (): void {
    this.bankAccount = {
      bankId: -1,
      accountType: '-1',
      accountNumber: '',
    }
  }

  async sendBankDetails (): Promise<void> {
    if (!this.$refs.form.validate()) return
    this.loadingForm = true
    const { getFoundPerson } = this
    if (getFoundPerson.id) {
      if (!this.accountFound) {
        await this.createBankDetails({
          bank: this.bankAccount,
          personId: getFoundPerson.id.toString(),
        })
      } else {
        this.accountFound.idBank = this.bankAccount.bankId
        this.accountFound.accountType.name = this.bankAccount.accountType
        this.updateBankDetails({
          bank: this.accountFound,
          personId: getFoundPerson.id.toString(),
          biPersonal: this.bipersonal,
        })
      }
    }
    this.loadingForm = false
    this.$router.back()
  }
  }
